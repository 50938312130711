exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-newsroom-announcements-jsx": () => import("./../../../src/pages/newsroom/announcements.jsx" /* webpackChunkName: "component---src-pages-newsroom-announcements-jsx" */),
  "component---src-pages-newsroom-covid-19-jsx": () => import("./../../../src/pages/newsroom/covid-19.jsx" /* webpackChunkName: "component---src-pages-newsroom-covid-19-jsx" */),
  "component---src-pages-newsroom-redcap-decommission-jsx": () => import("./../../../src/pages/newsroom/redcap-decommission.jsx" /* webpackChunkName: "component---src-pages-newsroom-redcap-decommission-jsx" */),
  "component---src-pages-newsroom-redcap-upgrade-jsx": () => import("./../../../src/pages/newsroom/redcap-upgrade.jsx" /* webpackChunkName: "component---src-pages-newsroom-redcap-upgrade-jsx" */),
  "component---src-pages-newsroom-redcap-validation-jsx": () => import("./../../../src/pages/newsroom/redcap-validation.jsx" /* webpackChunkName: "component---src-pages-newsroom-redcap-validation-jsx" */),
  "component---src-pages-newsroom-seasonal-closure-2023-jsx": () => import("./../../../src/pages/newsroom/seasonal-closure-2023.jsx" /* webpackChunkName: "component---src-pages-newsroom-seasonal-closure-2023-jsx" */),
  "component---src-pages-newsroom-seed-grant-jsx": () => import("./../../../src/pages/newsroom/seed-grant.jsx" /* webpackChunkName: "component---src-pages-newsroom-seed-grant-jsx" */),
  "component---src-pages-partners-jsx": () => import("./../../../src/pages/partners.jsx" /* webpackChunkName: "component---src-pages-partners-jsx" */),
  "component---src-pages-people-jsx": () => import("./../../../src/pages/people.jsx" /* webpackChunkName: "component---src-pages-people-jsx" */),
  "component---src-pages-publications-jsx": () => import("./../../../src/pages/publications.jsx" /* webpackChunkName: "component---src-pages-publications-jsx" */),
  "component---src-pages-resources-data-lifecycle-management-jsx": () => import("./../../../src/pages/resources/data-lifecycle-management.jsx" /* webpackChunkName: "component---src-pages-resources-data-lifecycle-management-jsx" */),
  "component---src-pages-resources-machine-learning-jsx": () => import("./../../../src/pages/resources/machine-learning.jsx" /* webpackChunkName: "component---src-pages-resources-machine-learning-jsx" */),
  "component---src-pages-resources-project-validation-guide-jsx": () => import("./../../../src/pages/resources/project-validation-guide.jsx" /* webpackChunkName: "component---src-pages-resources-project-validation-guide-jsx" */),
  "component---src-pages-services-and-pricing-custom-platforms-jsx": () => import("./../../../src/pages/services-and-pricing/custom-platforms.jsx" /* webpackChunkName: "component---src-pages-services-and-pricing-custom-platforms-jsx" */),
  "component---src-pages-services-and-pricing-dataxplor-jsx": () => import("./../../../src/pages/services-and-pricing/dataxplor.jsx" /* webpackChunkName: "component---src-pages-services-and-pricing-dataxplor-jsx" */),
  "component---src-pages-services-and-pricing-edc-services-jsx": () => import("./../../../src/pages/services-and-pricing/edc-services.jsx" /* webpackChunkName: "component---src-pages-services-and-pricing-edc-services-jsx" */),
  "component---src-pages-services-and-pricing-methods-analytics-services-jsx": () => import("./../../../src/pages/services-and-pricing/methods-analytics-services.jsx" /* webpackChunkName: "component---src-pages-services-and-pricing-methods-analytics-services-jsx" */),
  "component---src-pages-success-jsx": () => import("./../../../src/pages/success.jsx" /* webpackChunkName: "component---src-pages-success-jsx" */)
}

